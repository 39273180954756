class Slider {
	constructor(id) {
		this.isAnimating = false;
		this.elements = document.querySelectorAll(id);

		if (this.elements.length) {
			this.initSlider();
		}
	}

	initSlider() {
		var i;
		for (i = 0; i < this.elements.length; i++) {
			if (this.elements[i].firstElementChild.children.length >= 2) {
				this.initSwipe(this.elements[i]);
			}
		}
	}

	initSwipe(element) {
		var that = this;

		var slideshow = new Swipe(element, {
			continuous: true,
			speed: 800,
			auto: 10000,
			autoRestart: true,
			draggable: true,
			transitionEnd: function(index, elem) {
				that.isAnimating = false
			}
		});
		
		this.initControls(element, slideshow);
	}

	initControls(element, slideshow) {
		var prev = element.querySelector('.prev');
		var next = element.querySelector('.next');

		prev.addEventListener('click', () => {
			if (this.isAnimating) { return; }
			slideshow.prev();
			this.isAnimating = true;
		});

		next.addEventListener('click', () => {
			if (this.isAnimating) { return; }
			slideshow.next();
			this.isAnimating = true;
		});
	}
}